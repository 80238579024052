import styled, { css } from "styled-components";

import { device } from "../../../../../../utils/breakpoints";
import { fadeIn, fadeOut } from "../../../../../../utils/animations";
import {
  H2,
  H2_REGULAR,
  H3,
  H5,
  H6,
  SUBTITLE_1,
  TEXT_BODY_1,
} from "../../../../../../assets/styles/typography";
import RecruitmentFirstStageIcon from "-!svg-react-loader!../../../../../../assets/images/internships/recruitment1.svg";
import RecruitmentSecondStageIcon from "-!svg-react-loader!../../../../../../assets/images/internships/recruitment2.svg";
import RecruitmentThirdStageIcon from "-!svg-react-loader!../../../../../../assets/images/internships/recruitment3.svg";
import RecruitmentFourthStageIcon from "-!svg-react-loader!../../../../../../assets/images/internships/recruitment4.svg";
import RecruitmentFifthStageIcon from "-!svg-react-loader!../../../../../../assets/images/internships/recruitment5.svg";
import ArrowRight from "-!svg-react-loader!../../../../../../assets/images/arrowRight.svg";
import ArrowDown from "-!svg-react-loader!../../../../../../assets/images/arrowDown.svg";

interface PageProps {
  animation?: string;
}

export const PageWrapper = styled.div`
  position: relative;
  width: 100%;
  min-height: 100vh;
  padding: 112px 250px 48px 120px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #151515;
  animation: ${(props: PageProps) =>
    props.animation
      ? css`
          ${fadeOut} 300ms linear
        `
      : css`
          ${fadeIn} 300ms linear
        `};
  animation-fill-mode: forwards;

  @media ${device.computer} {
    padding: 128px 254px 80px 120px;
    height: auto;
  }

  @media ${device.desktop} {
    padding: 128px 325px 120px 120px;
    height: auto;
    justify-content: start;
  } ;
`;

export const RowWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: nowrap;

  @media ${device.computer} {
    align-items: flex-start;
  }
`;

export const RowItem = styled.div`
  width: 20%;
  height: 100%;
  min-height: 340px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & > svg {
    margin: 0;
  }

  &:nth-of-type(1n) {
    align-items: center;
    fill: #ff7500;
  }

  &:nth-of-type(2n) {
    margin: 0;
    align-items: center;

    svg > * {
      fill: #ffffff;
    }
  }

  @media ${device.computer} {
    width: 10%;
    &:nth-of-type(1n) {
      align-items: start;
    }
  }

  @media ${device.desktop} {
    min-height: 400px;
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: auto 120px;
  grid-template-rows: 120px 137px 120px 137px 120px 137px 120px 137px 120px 137px;

  & > svg {
    justify-self: center;

    &:nth-of-type(1n) {
      fill: #ff7500;
    }

    &:nth-of-type(2n) {
      rect {
        fill: white;
      }
    }
  }
`;

export const Title = styled.div`
  ${H3}
  width: 100%;
  margin: 0;
  color: #ffffff;
  white-space: pre-line;

  @media ${device.tablet}, ${device.tabletLandscape} {
    margin-bottom: 16px;
  }

  @media ${device.computer} {
    ${H3}
    margin-bottom: 16px;
  }

  @media ${device.desktop} {
    ${H2}
  } ;
`;

export const Description = styled.div`
  ${SUBTITLE_1}
  width: 100%;
  color: #ffffff;
  white-space: pre-line;

  @media ${device.tablet}, ${device.tabletLandscape} {
    margin-bottom: 48px;
  }

  @media ${device.computer} {
    margin-bottom: 48px;
  }
`;

export const DescriptionItem = styled.div`
  margin-top: 15px;
  display: flex;
  flex-direction: row;

  @media ${device.computer} {
    margin-top: 8px;
  }

  @media ${device.desktop} {
    margin-top: 15px;
  } ;
`;

export const DescriptionText = styled.div`
  padding-left: 5px;
`;

export const HorizontalDivider = styled.hr`
  width: 96px;
  height: 8px;
  background: #ffffff;
  margin-bottom: 80px;

  @media ${device.computer} {
    margin-bottom: 88px;
  }

  @media ${device.desktop} {
    margin-bottom: 147px;
  }
`;

export const StageWrapper = styled.div`
  flex: 1;
  margin-top: 4vh;

  @media ${device.tablet}, ${device.tabletLandscape} {
    margin-top: 0;
    grid-row-end: span 2;
    min-height: 300px;
  }

  @media ${device.tabletLandscape} {
    &:last-of-type {
      min-height: 164px;
    }
  }

  @media ${device.computer} {
    margin-top: 32px;
  }

  @media ${device.desktop} {
    margin-top: 35px;
  } ;
`;

export const StageNumber = styled.div`
  ${H2_REGULAR}
  margin-bottom: 16px;
  color: #ffffff;
`;

export const StageTitle = styled.span`
  ${H6}
  display: block;
  color: #ffffff;
  white-space: pre-line;

  @media ${device.desktop} {
    ${H5}
  }
`;

export const StageDescription = styled.div`
  ${TEXT_BODY_1}
  color: #FFFFFF;
  margin-top: 16px;

  @media ${device.tablet} {
    width: 266px;
  }

  @media ${device.computer} {
    width: 160px;
  }

  @media ${device.desktop} {
    ${SUBTITLE_1}
    width: 244px;
  }
`;

export const Recruitment1StageIcon = styled(RecruitmentFirstStageIcon)`
  @media ${device.tablet}, ${device.tabletLandscape} {
    width: 64px;
    height: 68px;
  }

  @media ${device.computer} {
    width: 64px;
    height: 68px;
  }

  @media ${device.desktop} {
    width: 98px;
    height: 87px;
  }
`;

export const Recruitment2StageIcon = styled(RecruitmentSecondStageIcon)`
  @media ${device.tablet}, ${device.tabletLandscape} {
    width: 64px;
    height: 68px;
  }

  @media ${device.computer} {
    width: 64px;
    height: 68px;
  }

  @media ${device.desktop} {
    width: 98px;
    height: 87px;
  }
`;

export const Recruitment3StageIcon = styled(RecruitmentThirdStageIcon)`
  @media ${device.tablet}, ${device.tabletLandscape} {
    width: 64px;
    height: 68px;
  }

  @media ${device.computer} {
    width: 64px;
    height: 68px;
  }

  @media ${device.desktop} {
    width: 98px;
    height: 87px;
  }
`;

export const Recruitment4StageIcon = styled(RecruitmentFourthStageIcon)`
  @media ${device.tablet}, ${device.tabletLandscape} {
    width: 64px;
    height: 68px;
  }

  @media ${device.computer} {
    width: 64px;
    height: 68px;
  }

  @media ${device.desktop} {
    width: 98px;
    height: 87px;
  }
`;

export const Recruitment5StageIcon = styled(RecruitmentFifthStageIcon)`
  @media ${device.tablet}, ${device.tabletLandscape} {
    width: 64px;
    height: 68px;
  }

  @media ${device.computer} {
    width: 64px;
    height: 68px;
  }

  @media ${device.desktop} {
    width: 98px;
    height: 87px;
  }
`;

export const DownArrow = styled(ArrowDown)`
  height: 47px;
`;

export const RightArrow = styled(ArrowRight)`
  @media ${device.computer} {
    width: 47px;
  }

  @media ${device.desktop} {
    width: 78px;
  } ;
`;
