import React from "react";
import { useTranslation } from "react-i18next";

import {
  PageWrapper,
  Title,
  Description,
  HorizontalDivider,
  RowWrapper,
  RowItem,
  Grid,
  StageWrapper,
  StageNumber,
  StageTitle,
  StageDescription,
  Recruitment1StageIcon,
  Recruitment2StageIcon,
  Recruitment3StageIcon,
  Recruitment4StageIcon,
  Recruitment5StageIcon,
  DownArrow,
  RightArrow
} from "./style";
import { PageChange } from "../../../../../common/Scroll";
import Breakpoint from "../../../../../common/Breakpoint";
import { fixConjunctions } from "../../../../../../utils/dropTheWord";
import { PageTitle } from "../../../../../common/PageTitle";

interface RecruitmentProps {
  pageChange: PageChange;
}

const Internships: React.FC<RecruitmentProps> = ({ pageChange }) => {
  const { t } = useTranslation("career");

  return (
    <>
      <PageTitle subtitle={fixConjunctions(t(`internships_title`))} />
      <PageWrapper animation={pageChange}>
        <div>
          <Title>{fixConjunctions(t(`internships_title`))}</Title>
          <Description>
            {fixConjunctions(t(`recruitment_description`))}
          </Description>
          <HorizontalDivider />
        </div>

        <Breakpoint device={["tablet", "tabletLandscape"]}>
          <Grid>
            <StageWrapper>
              <StageNumber>01</StageNumber>
              <StageTitle
                dangerouslySetInnerHTML={{
                  __html: fixConjunctions(t(`internships_firstStep`)),
                }}
              />
            </StageWrapper>
            <Recruitment1StageIcon />
            <DownArrow />
            <StageWrapper>
              <StageNumber>02</StageNumber>
              <StageTitle
                dangerouslySetInnerHTML={{
                  __html: fixConjunctions(t(`internships_secondStep`)),
                }}
              ></StageTitle>
              <StageDescription
                dangerouslySetInnerHTML={{
                  __html: fixConjunctions(
                    t(`internships_secondStepDescription`)
                  ),
                }}
              />
            </StageWrapper>
            <Recruitment2StageIcon />
            <DownArrow />
            <StageWrapper>
              <StageNumber>03</StageNumber>
              <StageTitle
                dangerouslySetInnerHTML={{
                  __html: fixConjunctions(t(`internships_thirdStep`)),
                }}
              />
              <StageDescription
                dangerouslySetInnerHTML={{
                  __html: fixConjunctions(
                    t(`internships_thirdStepDescription`)
                  ),
                }}
              />
            </StageWrapper>
            <Recruitment3StageIcon />
            <DownArrow />
            <StageWrapper>
              <StageNumber>04</StageNumber>
              <StageTitle
                dangerouslySetInnerHTML={{
                  __html: fixConjunctions(t(`internships_fourthStep`)),
                }}
              />
            </StageWrapper>
            <Recruitment4StageIcon />
            <DownArrow />
            <StageWrapper>
              <StageNumber>05</StageNumber>
              <StageTitle
                dangerouslySetInnerHTML={{
                  __html: fixConjunctions(t(`internships_fifthStep`)),
                }}
              />
              <StageDescription
                dangerouslySetInnerHTML={{
                  __html: fixConjunctions(
                    t(`internships_fifthStepDescription`)
                  ),
                }}
              />
            </StageWrapper>
            <Recruitment5StageIcon />
          </Grid>
        </Breakpoint>

        <Breakpoint device="computer">
          <RowWrapper>
            <RowItem>
              <Recruitment1StageIcon />
              <StageWrapper>
                <StageNumber>01</StageNumber>
                <StageTitle
                  dangerouslySetInnerHTML={{
                    __html: fixConjunctions(t(`internships_firstStep`)),
                  }}
                />
              </StageWrapper>
            </RowItem>
            <RowItem>
              <RightArrow />
            </RowItem>
            <RowItem>
              <Recruitment2StageIcon />
              <StageWrapper>
                <StageNumber>02</StageNumber>
                <StageTitle
                  dangerouslySetInnerHTML={{
                    __html: fixConjunctions(t(`internships_secondStep`)),
                  }}
                ></StageTitle>
                <StageDescription
                  dangerouslySetInnerHTML={{
                    __html: fixConjunctions(
                      t(`internships_secondStepDescription`)
                    ),
                  }}
                />
              </StageWrapper>
            </RowItem>
            <RowItem>
              <RightArrow />
            </RowItem>
            <RowItem>
              <Recruitment3StageIcon />
              <StageWrapper>
                <StageNumber>03</StageNumber>
                <StageTitle
                  dangerouslySetInnerHTML={{
                    __html: fixConjunctions(t(`internships_thirdStep`)),
                  }}
                />
                <StageDescription
                  dangerouslySetInnerHTML={{
                    __html: fixConjunctions(
                      t(`internships_thirdStepDescription`)
                    ),
                  }}
                />
              </StageWrapper>
            </RowItem>
            <RowItem>
              <RightArrow />
            </RowItem>
            <RowItem>
              <Recruitment4StageIcon />
              <StageWrapper>
                <StageNumber>04</StageNumber>
                <StageTitle
                  dangerouslySetInnerHTML={{
                    __html: fixConjunctions(t(`internships_fourthStep`)),
                  }}
                />
              </StageWrapper>
            </RowItem>
            <RowItem>
              <RightArrow />
            </RowItem>
            <RowItem>
              <Recruitment5StageIcon />
              <StageWrapper>
                <StageNumber>05</StageNumber>
                <StageTitle
                  dangerouslySetInnerHTML={{
                    __html: fixConjunctions(t(`internships_fifthStep`)),
                  }}
                />
                <StageDescription
                  dangerouslySetInnerHTML={{
                    __html: fixConjunctions(
                      t(`internships_fifthStepDescription`)
                    ),
                  }}
                />
              </StageWrapper>
            </RowItem>
          </RowWrapper>
        </Breakpoint>
      </PageWrapper>
    </>
  );
};

export default Internships;
